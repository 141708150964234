import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				A propos de nous | A propos de Gallic
			</title>
			<meta name={"description"} content={"Revitalisez votre véhicule avec Gallic - Votre partenaire de confiance en matière de bien-être automobile.\n"} />
			<meta property={"og:title"} content={"A propos de nous | A propos de Gallic"} />
			<meta property={"og:description"} content={"Revitalisez votre véhicule avec Gallic - Votre partenaire de confiance en matière de bien-être automobile.\n"} />
			<meta property={"og:image"} content={"https://gallic.live/media/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://gallic.live/media/523523522535.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://gallic.live/media/523523522535.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://gallic.live/media/523523522535.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://gallic.live/media/523523522535.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://gallic.live/media/523523522535.png"} />
			<meta name={"msapplication-TileImage"} content={"https://gallic.live/media/523523522535.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline1"
					lg-text-align="center"
					md-text-align="left"
				>
					Révéler l'essence
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Gallic n'est pas seulement un centre d'entretien - c'est un havre pour les passionnés d'automobile qui recherchent une approche fiable et complète de l'entretien de leur véhicule. Notre parcours a commencé avec la vision de redéfinir l'entretien automobile en combinant l'expertise technique avec une véritable passion pour l'automobile. Si nous croyons au pouvoir d'un véhicule bien entretenu, nous comprenons que le lien qui unit chaque conducteur à sa voiture va au-delà de la mécanique.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://gallic.live/media/4.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Notre engagement
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Animé par un engagement d'excellence, Gallic s'efforce de créer un environnement où les clients se sentent appréciés et compris. Notre équipe de professionnels expérimentés se consacre à fournir un service de premier ordre, en veillant à ce que votre véhicule reçoive l'attention qu'il mérite. Au-delà des outils et de l'équipement, c'est le dévouement au métier qui distingue Gallic.
					</Text>
				</Box>
				<Image src="https://gallic.live/media/5.jpg" display="block" width="100%" md-order="-1" />
				<Image src="https://gallic.live/media/1.jpg" display="block" width="100%" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Pourquoi choisir Gallic ?
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						- Des services complets : De l'entretien de routine aux réparations spécialisées, Gallic propose une gamme de services pour maintenir votre véhicule dans un état optimal.
						<br />
						<br />
						- Des techniciens compétents : Notre équipe est composée de techniciens qualifiés et certifiés qui abordent chaque tâche avec précision et soin.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="30px 0 30px 0" md-padding="40px 0 40px 0" quarkly-title="About-13">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="grid"
				grid-gap="32px"
				sm-grid-gap="16px"
				grid-template-columns="4fr 5fr"
				align-items="center"
				width="50%"
				lg-width="100%"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Image
					src="https://gallic.live/media/7.jpg"
					object-fit="cover"
					width="100%"
					height="80%"
					lg-height="100%"
				/>
				<Image
					src="https://gallic.live/media/6.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					max-height="460px"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				padding="42px 42px 42px 42px"
				lg-width="100%"
				md-padding="24px 24px 24px 24px"
				md-margin="24px 0px 0px 0px"
				sm-align-items="flex-start"
				sm-padding="24px 24px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Découvrez l'expérience Gallic
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="center"
					sm-text-align="left"
				>
					Gallic vous invite à découvrir des soins automobiles qui sortent de l'ordinaire. Rejoignez-nous dans notre engagement à maintenir votre véhicule en parfait état tout en profitant de l'atmosphère amicale et accueillante qui nous distingue.
				</Text>
				<Link
					href="/contacts"
					color="--lightD1"
					text-decoration-line="initial"
					padding="11px 44px 12px 44px"
					background="--color-primary"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					border-radius="36px"
					letter-spacing="1px"
				>
					Contactez-nous
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});